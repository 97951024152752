// See usage on https://ramdajs.com/
import curry from 'ramda/src/curry';

import { Curry2 } from '../../types';

type FormatterData = unknown;
type FormatterOptions = {
  parser: (data: FormatterData) => string;
  fallbackEmptyMessage?: string;
};

const formatterHelper = (
  formatterOptions: FormatterOptions,
  data: FormatterData,
) => {
  const {
    parser,
    fallbackEmptyMessage = '',
  } = formatterOptions;

  if (!parser) {
    return fallbackEmptyMessage;
  }

  const parserResult = parser(data);
  return parserResult || fallbackEmptyMessage;
};

const curriedFormatter: Curry2<
  FormatterOptions,
  FormatterData
> = curry(formatterHelper);

export { curriedFormatter as formatter };
